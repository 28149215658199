import { useTheme } from '@material-ui/core'
import get from 'lodash/get'

export default function useIsPhone() {
    const { sm } = useTheme().breakpoints.values
    
    if(typeof window !== 'undefined') {
        const isNarrow = window.screen.width < sm
        const hasTouch = get(window, 'Modernizr.touchevents')
        return (isNarrow && hasTouch)
    }

    return false
}