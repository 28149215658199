import { useMemo } from 'react'

export function useSvgController(svgContent: string, duration: number) {
    return useMemo(() => {
        if (typeof window === 'undefined') { return undefined }

        const wrapper = window.document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        wrapper.innerHTML = svgContent
        const svgElement = wrapper.firstElementChild as SVGGElement

        const paths = svgElement.querySelectorAll('path')
        const pathLengths: number[] = []

        for (let i = 0; i <paths.length; i++) {
            const p = paths.item(i)
            const l = p.getTotalLength()
            pathLengths[i] = l
            p.style.strokeDasharray = l + 'px'
            p.style.strokeDashoffset = l + 'px'
            p.style.transition = `${duration}ms stroke-dashoffset`
        }

        const setDisplay = (display: boolean) => {
            for (let i = 0; i <paths.length; i++) {
                const p = paths.item(i)
                if(display) {
                    p.style.strokeDashoffset = '0px'
                } else {
                    p.style.strokeDashoffset = pathLengths[i]+'px'
                }
            }
        }

        return {
            paths,
            setDisplay,
        }
    }, [svgContent, duration])
}