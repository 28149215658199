import { Container } from '@material-ui/core'
import { graphql } from 'gatsby'
import React from 'react'
import useIsPhone from '../components/hooks/is-phone'
import NavigationBar from '../components/navigation-bar/navigation-bar'
import NoPhone from '../components/page-projects-components/no-phone'
import Phone from '../components/page-projects-components/phone'
import { Node } from '../components/page-projects-components/types'

interface Props {
    data: {
        allMarkdownRemark: {
            nodes?: Node[]
        }
    }
}

const duration = 750
const typoDelay = 160

export default function ProjectsPage({data}: Props) {
    NavigationBar.useSetTitle('Projects')
    const isPhone = useIsPhone()
    const nodes = data.allMarkdownRemark.nodes

    return <Container
        maxWidth='md'
        children={
            isPhone ?
            <Phone
                duration={duration}
                nodes={nodes ?? []}
                typoDelay={typoDelay}
            /> :
            <NoPhone
                duration={duration}
                nodes={nodes ?? []}
                typoDelay={typoDelay}
            />
        }
    />
}

export const pageQuery = graphql`query a {
    site {
        siteMetadata { title }
    }
    allMarkdownRemark(
        # sort: {fields: [fields___slug], order: ASC},
        filter: {fields: {sourceName: {eq: "projects"}}}
    ) {
        nodes {
            id
            excerpt
            fields { path }
            frontmatter {
                title
                subtitle
                svgLogo {
                    fields { svgContent }
                }
            }
        }
    }
}`
