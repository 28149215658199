import { Grid, Typography } from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ProjectElement from '../project-element/project-element'
import { Node } from './types'

interface Props {
    nodes: Node[]
    duration: number
    typoDelay: number
}

export default function Phone({nodes, duration, typoDelay}: Props) {
    const [destination, setDestination] = useState<string|undefined>()
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if(destination !== undefined) {
            const h = setTimeout(() => navigate(destination), duration)
            return () => clearTimeout(h)
        }
    }, [destination])

    useEffect(() => {
        let from: number[] | undefined = undefined
        let to: number[] | undefined = undefined
        const T = nodes.length

        const start = (e: TouchEvent) => {
            // e.preventDefault()
            const t = e.touches.item(0)
            if(t) {
                from = [t.clientX, t.clientY]
            }
        }

        const move = (e: TouchEvent) => {
            e.preventDefault()
            const t = e.touches.item(0)
            if(t) {
                to = [t.clientX, t.clientY]
            }
        }

        const end = () => {
            if(from && to) {
                const dy = from[1] - to[1] 
                if(Math.abs(dy) > 30) {
                    from = to = undefined
                    setActiveIndex(v => (v+Math.sign(dy)+T)%T)
                }
            }
        }

        window.addEventListener('touchstart', start, {passive: false})
        window.addEventListener('touchmove', move, {passive: false})
        window.addEventListener('touchend', end)

        return () => {
            window.removeEventListener('touchstart', start)
            window.removeEventListener('touchmove', move)
            window.removeEventListener('touchend', end)
        }
    }, [nodes])

    return <Link
        style={{textDecoration: 'none', color: 'unset'}}
        to={nodes?.[activeIndex].fields.path}
        onClick={(event) => {
            event.preventDefault()
            setDestination(nodes?.[activeIndex].fields.path)
        }}
        children={nodes?.map(({id, frontmatter: {subtitle, title, svgLogo}, fields: {path}}, index) => {
            if(Math.abs(activeIndex - index) > 2) return null
            return (
            <Grid
                key={id}
                container direction='column'
                style={{height:'calc(100vh - 100px)',position: 'fixed', bottom: 16, left: 0}}
            >
                <Grid
                    item xs
                    style={{flex: '1 1', overflow: 'hidden'}}
                    children={
                        svgLogo?.fields.svgContent &&
                        <ProjectElement
                            svgLogoContent={svgLogo?.fields.svgContent}
                            display={destination === undefined && activeIndex === index}
                            duration={duration}
                            style={{width:'100%', height: '100%'}}
                        />
                    }
                />
                <Grid
                    item
                    style={{
                        flex: '0 0',
                        opacity: destination === undefined && activeIndex === index ? 1 : 0,
                        transition: `${duration}ms opacity`,
                        minHeight: '100px'
                    }}
                >
                    <Typography
                        variant='h5'
                        align='center'
                        style={{fontFamily: 'Fira Sans, sans-serif'}}
                        dangerouslySetInnerHTML={{ __html: title}}
                        gutterBottom
                    />
                    <Typography
                        variant='body2'
                        gutterBottom
                        children={subtitle}
                        align='center'
                    />
                </Grid>
            </Grid>)
        })}
    />
}
