import React, { useEffect, useState } from 'react'
import { useSvgController } from './components/hooks'

interface Props {
    svgLogoContent: string
    display: boolean
    duration: number
    style?: React.CSSProperties
}

export default function ProjectElement({svgLogoContent, display, style={}, duration}: Props) {
    const [ref, setRef] = useState<SVGSVGElement|null>(null)
    const [apended, setApended] = useState(false)

    const {paths, setDisplay} = useSvgController(svgLogoContent, duration) ?? {}

    useEffect(() => {
        if(ref && paths) {
            const observer = new MutationObserver(() => {
                setApended(true)
                observer.disconnect()
            })
            
            observer.observe(ref, {childList: true})
            
            for (let i = 0; i < paths.length; i++) {
                ref.appendChild(paths[i])
            }

            return () => observer.disconnect()
        }
    }, [ref, paths])

    useEffect(() => {
        if(setDisplay && apended) {
            const h = setTimeout(() => {
                setDisplay(display)
            }, 0)

            return () => clearTimeout(h)
        }
    }, [setDisplay, display, apended])

    return <svg
        ref={setRef}
        style={style}
        width='450'
        height='450'
        viewBox='0 0 450 450'
    />
}