import { Grid, Typography } from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import ProjectElement from '../project-element/project-element'
import { Node } from './types'

interface Props {
    nodes: Node[]
    duration: number
    typoDelay: number
}

export default function NoPhone({nodes, duration, typoDelay}: Props) {
    const [destination, setDestination] = useState<string|undefined>()
    const [displayTypo, setDisplayTypo] = useState(false)

    useEffect(() => {
        if(destination !== undefined) {
            const h = setTimeout(() => {
                navigate(destination)
            }, duration)

            return () => clearTimeout(h)
        }
    }, [destination])

    useEffect(() => {
        const h = setTimeout(() => {
            setDisplayTypo(true)
        }, typoDelay)

        return () => clearTimeout(h)
    }, [])

    const typoStyle = useMemo<React.CSSProperties>(() => {
        return {
            transition: `${duration - (typoDelay*2)}ms opacity`,
            opacity: (displayTypo && destination === undefined) ? 1 : 0
        }
    }, [displayTypo, destination])

    return <Grid
        container
        spacing={2}
        children={nodes?.map(
            ({id, frontmatter: {subtitle, title, svgLogo}, fields: {path}}
        ) => (
            <Grid item xs={12} sm={6} md={4} key={id}>
                <Link
                    to={path}
                    style={{textDecoration: 'none', color: 'unset'}}
                    onClick={(event) => {
                        event.preventDefault()
                        setDestination(path)
                    }}
                >
                    {
                        svgLogo?.fields.svgContent &&
                        <ProjectElement
                            svgLogoContent={svgLogo?.fields.svgContent}
                            display={destination === undefined}
                            duration={duration}
                            style={{width:'100%', height: 'auto'}}
                        />
                    }
                    <Typography
                        variant='h5'
                        align='center'
                        style={{fontFamily: 'Fira Sans, sans-serif', ...typoStyle}}
                        dangerouslySetInnerHTML={{ __html: title}}
                        gutterBottom
                    />
                    <Typography
                        variant='body2'
                        gutterBottom
                        children={subtitle}
                        style={{...typoStyle}}
                        align='center'
                    />
                </Link>
            </Grid>
        ))}
    />
}
